<template>
    <v-row class="row--x-small search--primary">
        <v-spacer></v-spacer>
        <v-col cols="12" md="auto">
            <v-select class="v-input--small w-100 w-md-120px" outlined hide-details></v-select>
        </v-col>
        <v-col cols="9" md="auto">
            <v-text-field class="v-input--small w-100 w-md-240px" outlined hide-details placeholder="검색어를 입력하세요." />
        </v-col>
        <v-col cols="3" md="auto">
            <v-btn class="h-100 w-100 min-w-md-60px" color="grey-6">
                <span class="white--text font-size-14">검색</span>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>

export default {
    props: {
    },
    data() {
        return {
        }
    },
}
</script>
