<template>
    <div class="board__list">
        <v-row no-gutters align="center" class="head-tab mb-10 mb-lg-20">
            <v-col cols="12" lg="" class="mb-20 mb-lg-0">
                <h3 class="font-size-20 font-size-lg-28">
                    자주 묻는 궁금증
                </h3>
            </v-col>
            <v-spacer />
            <v-col cols="12" lg="auto">
                <!-- <search-primary /> -->
                <v-row class="row--x-small search--primary">
                    <v-spacer></v-spacer>
                    <v-col cols="12" md="auto">
                        <v-select v-model="filter.searchKey" :items="searchKeys" class="v-input--small w-100 w-md-120px" outlined hide-details @input="search(true)"></v-select>
                    </v-col>
                    <v-col cols="9" md="auto">
                        <v-text-field v-model="filter.searchValue" class="v-input--small w-100 w-md-240px" outlined hide-details placeholder="검색어를 입력하세요." :disabled="!filter.searchKey" @input="search(true)" />
                    </v-col>
                    <v-col cols="3" md="auto">
                        <v-btn class="h-100 w-100 min-w-md-60px" color="grey-6">
                            <span class="white--text font-size-14" @click="search(false)">검색</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <div class="board__list--qna">
            <!-- S::qna 게시글 -->
            <div v-for="faq in faqs" :key="faq._id" class="board__qna-item board-item__tit">
                <div class="board__qna-item">
                    <div class="board__qna-item__row">
                        <v-row align="center">
                            <v-col cols="auto">
                                <h3 class="tit tit--sm primary--text">Q</h3>
                            </v-col>
                            <v-col cols="auto">
                                <v-chip color="primary-50" text-color="primary">
                                    <!-- 인터넷 -->
                                    {{ faq?.category }}
                                </v-chip>
                            </v-col>
                            <v-col class="board__qna-item__tit">
                                <p class="page-text grey-1--text font-weight-medium ellip">
                                    <!-- 질문이 보여집니다. 질문이 보여집니다. 질문이 보여집니다. 질문이 보여집니다. 질문이 보여집니다. 질문이 보여집니다. -->
                                    {{ faq?.subject }}
                                </p>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="board__qna-item__contents board__qna-item__row">
                        <v-row align="center">
                            <v-col cols="auto">
                                <h3 class="tit tit--sm grey-6--text">A</h3>
                            </v-col>
                            <v-col cols="auto">
                                <v-card elevation="0" class="hidden">
                                    <v-img :src="`${faq?.reply?.thumb}`" :aspect-ratio="1 / 1" class="board__qna__img" />
                                </v-card>
                            </v-col>
                            <v-col class="board__qna__txt page-text">
                                <p class="font-weight-medium mb-2 mb-lg-4">
                                    <!-- 알코올치료센터장 홍길동 -->
                                    {{ faq?.reply?.name }}
                                </p>
                                <p v-html="faq?.reply?.content" class="faq__detail grey-6--text ellip--4"></p>
                            </v-col>
                            <v-col cols="12" md="auto" class="d-flex justify-end mt-10 mt-md-0">
                                <v-btn :to="`/information/faq/${faq._id}`" x-small rounded color="grey-6">
                                    <i class="icon icon-plus mr-4"></i>
                                    <span class="white--text">더 보기</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
            <!-- E::qna 게시글 -->
        </div>

        <div class="v-pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search(false)" />
        </div>
    </div>
</template>

<script>
import api from "@/api";
import SearchPrimary from "@/components/client/board/search--primary.vue";

export default {
    components: {
        SearchPrimary,
    },

    data() {
        return {
            faqs: [],

            page: 1,
            limit: 4,
            pageCount: 0,

            filter: {
                searchKey: this.$route.query.searchKey || "subjectcontent",
                searchValue: this.$route.query.searchValue || null,
            },

            searchKeys: [
                { text: "제목 + 내용", value: "subjectcontent" },
                { text: "제목", value: "subject" },
            ],
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            await this.search();
        },
        async search(routable = false) {
            if (routable) {
                this.$router.push({ query: { ...this.filter } });
                return;
            }
            let { summary, faqs } = await api.v1.center.faqs.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: { ...this.filter },
            });
            this.faqs = faqs;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
    },
};
</script>
<style lang="scss" scoped>
</style>
