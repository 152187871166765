var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board__list"
  }, [_c('v-row', {
    staticClass: "head-tab mb-10 mb-lg-20",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "mb-20 mb-lg-0",
    attrs: {
      "cols": "12",
      "lg": ""
    }
  }, [_c('h3', {
    staticClass: "font-size-20 font-size-lg-28"
  }, [_vm._v(" 자주 묻는 궁금증 ")])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--x-small search--primary"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "v-input--small w-100 w-md-120px",
    attrs: {
      "items": _vm.searchKeys,
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "9",
      "md": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "v-input--small w-100 w-md-240px",
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "검색어를 입력하세요.",
      "disabled": !_vm.filter.searchKey
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3",
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-100 w-100 min-w-md-60px",
    attrs: {
      "color": "grey-6"
    }
  }, [_c('span', {
    staticClass: "white--text font-size-14",
    on: {
      "click": function ($event) {
        return _vm.search(false);
      }
    }
  }, [_vm._v("검색")])])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "board__list--qna"
  }, _vm._l(_vm.faqs, function (faq) {
    var _faq$reply, _faq$reply2, _faq$reply3;
    return _c('div', {
      key: faq._id,
      staticClass: "board__qna-item board-item__tit"
    }, [_c('div', {
      staticClass: "board__qna-item"
    }, [_c('div', {
      staticClass: "board__qna-item__row"
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('h3', {
      staticClass: "tit tit--sm primary--text"
    }, [_vm._v("Q")])]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-chip', {
      attrs: {
        "color": "primary-50",
        "text-color": "primary"
      }
    }, [_vm._v(" " + _vm._s(faq === null || faq === void 0 ? void 0 : faq.category) + " ")])], 1), _c('v-col', {
      staticClass: "board__qna-item__tit"
    }, [_c('p', {
      staticClass: "page-text grey-1--text font-weight-medium ellip"
    }, [_vm._v(" " + _vm._s(faq === null || faq === void 0 ? void 0 : faq.subject) + " ")])])], 1)], 1), _c('div', {
      staticClass: "board__qna-item__contents board__qna-item__row"
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('h3', {
      staticClass: "tit tit--sm grey-6--text"
    }, [_vm._v("A")])]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-card', {
      staticClass: "hidden",
      attrs: {
        "elevation": "0"
      }
    }, [_c('v-img', {
      staticClass: "board__qna__img",
      attrs: {
        "src": `${faq === null || faq === void 0 ? void 0 : (_faq$reply = faq.reply) === null || _faq$reply === void 0 ? void 0 : _faq$reply.thumb}`,
        "aspect-ratio": 1 / 1
      }
    })], 1)], 1), _c('v-col', {
      staticClass: "board__qna__txt page-text"
    }, [_c('p', {
      staticClass: "font-weight-medium mb-2 mb-lg-4"
    }, [_vm._v(" " + _vm._s(faq === null || faq === void 0 ? void 0 : (_faq$reply2 = faq.reply) === null || _faq$reply2 === void 0 ? void 0 : _faq$reply2.name) + " ")]), _c('p', {
      staticClass: "faq__detail grey-6--text ellip--4",
      domProps: {
        "innerHTML": _vm._s(faq === null || faq === void 0 ? void 0 : (_faq$reply3 = faq.reply) === null || _faq$reply3 === void 0 ? void 0 : _faq$reply3.content)
      }
    })]), _c('v-col', {
      staticClass: "d-flex justify-end mt-10 mt-md-0",
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('v-btn', {
      attrs: {
        "to": `/information/faq/${faq._id}`,
        "x-small": "",
        "rounded": "",
        "color": "grey-6"
      }
    }, [_c('i', {
      staticClass: "icon icon-plus mr-4"
    }), _c('span', {
      staticClass: "white--text"
    }, [_vm._v("더 보기")])])], 1)], 1)], 1)])]);
  }), 0), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    staticClass: "mt-4 mb-12",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function ($event) {
        return _vm.search(false);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }